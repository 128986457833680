export const iconSet = {
  'back-to-top': 'IconBackToTop',
  check: 'IconCheck',
  'check-circle': 'IconCheckCircle',
  'check-shield': 'IconCheckShield',
  'chevron-down': 'IconChevronDown',
  'chevron-left': 'IconChevronLeft',
  'chevron-right': 'IconChevronRight',
  'chevron-up': 'IconChevronUp',
  clear: 'IconClear',
  close: 'IconClose',
  compare: 'IconCompare',
  'credit-card': 'IconCreditCard',
  delivery: 'IconDelivery',
  devices: 'IconDevices',
  disconnect: 'IconDisconnect',
  download: 'IconDownload',
  'empty-cart': 'IconEmptyCart',
  facebook: 'IconFacebook',
  geo: 'IconGeo',
  hamburger: 'IconHamburger',
  'hint-pinch': 'IconHintPinch',
  'hint-zoom': 'IconHintZoom',
  home: 'IconHome',
  instagram: 'IconInstagram',
  'in-stock': 'IconInStock',
  link: 'IconLink',
  mail: 'IconMail',
  minus: 'IconMinus',
  'order-check': 'IconOrderCheck',
  'out-of-stock': 'IconOutOfStock',
  phone: 'IconPhone',
  pinterest: 'IconPinterest',
  plus: 'IconPlus',
  remove: 'IconRemove',
  search: 'IconSearch',
  share: 'IconShare',
  'shopping-bag': 'IconShoppingBag',
  'shopping-cart': 'IconShoppingCart',
  'size-chart': 'IconSizeChart',
  star: 'IconStar',
  'tooltip-info': 'IconTooltipInfo',
  track: 'IconTrack',
  twitter: 'IconTwitter',
  user: 'IconUser',
  wishlist: 'IconWishlist',
  youtube: 'IconYoutube'
}
export const flagSet = {
  'flag-ca': 'IconFlagCa',
  'flag-gb': 'IconFlagGb',
  'flag-us': 'IconFlagUs'
}
export const paymentIconSet = {
  'payment-adyen': 'IconAdyen',
  'payment-amex': 'IconAmex',
  'payment-apple-pay': 'IconApplePay',
  'payment-diners': 'IconDiners',
  'payment-discover': 'IconDiscover',
  'payment-mc': 'IconMc',
  'payment-paypal': 'IconPaypal',
  'payment-visa': 'IconVisa'
}
export const spriteIcons = { search: 'IconSearch', wishlist: 'IconWishlist' }

export type AsfIconSet = keyof typeof iconSet
export type AsfFlagSet = keyof typeof flagSet
export type AsfPaymentIconSet = keyof typeof paymentIconSet
export type AsfSpriteIconsSet = keyof typeof spriteIcons
