import {
  type AsfFlagSet,
  type AsfIconSet,
  type AsfPaymentIconSet,
  flagSet,
  iconSet,
  paymentIconSet,
  spriteIcons
} from '@ui/icons'

export const useIcon = () => {
  const getIconComponent = (iconName: AsfIconSet | AsfFlagSet | AsfPaymentIconSet) => {
    if (!iconName) {
      return ''
    }

    if (Object.keys(spriteIcons).includes(iconName)) {
      return defineAsyncComponent(() => import('@ui/icons/IconSprite.vue'))
    }

    if (iconName.startsWith('flag')) {
      return defineAsyncComponent(() => import(`@ui/icons/flags/${flagSet[iconName as AsfFlagSet]}.vue`))
    }

    if (iconName.startsWith('payment')) {
      return defineAsyncComponent(
        () => import(`@ui/icons/payment-methods/${paymentIconSet[iconName as AsfPaymentIconSet]}.vue`)
      )
    }

    return defineAsyncComponent(() => import(`@ui/icons/components/${iconSet[iconName as AsfIconSet]}.vue`))
  }

  return { getIconComponent }
}
