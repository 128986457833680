<script setup lang="ts">
import type { AsfIconProps } from '@ui/types'

const props = withDefaults(defineProps<AsfIconProps>(), {
  id: 'icon',
  size: '6',
  preserveAspectRatio: 'none'
})

const { getIconComponent } = useIcon()
const { sizeProps } = useSize(props)

const iconComponent = computed(() => props.name && getIconComponent(props.name))

const idProp = computed(() => `icon-${props.id || props.name}`)
const a11yProps = computed(() => {
  const properties: Record<string, string> = { role: 'img' }
  if (props.title?.length) {
    properties['aria-labelledby'] = idProp.value
  } else {
    properties['aria-hidden'] = 'true'
    properties.tabindex = '-1'
  }

  if (props.name === 'star') {
    properties.rid = idProp.value
  }

  return properties
})
</script>
<template>
  <span class="asf-icon">
    <component
      v-if="iconComponent"
      :is="iconComponent"
      :name="name"
      :preserveAspectRatio="preserveAspectRatio"
      :style="{
        '--width': sizeProps.width,
        '--height': sizeProps.height
      }"
      v-bind="{ ...a11yProps }"
      class="asf-icon__item"
      focusable="false"
    >
      <template #icon-title v-if="title">
        <title :id="idProp">{{ title }}</title>
      </template>
    </component>
    <span v-else>Property name for icon is not defined</span>
  </span>
</template>
<style lang="postcss">
@import '@components/atoms/Icon/Icon.css';
</style>
